import React from 'react';

import Layout from '@common/Layout';
import { Container } from '@components/global';

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1>NOT FOUND</h1>
      <p>You just hit a page that doesn&#39;t exist...M</p>
      <p><a href="/">Back to site</a></p>
    </Container>
  </Layout>
);

export default NotFoundPage;
